import './ManagerOrderEditModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal/Modal';
import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

import { saveManagerOrderInfo } from '../../_reducers/user';

export default function ManagerOrderEditModal(props) {
    const dispatch = useDispatch();

    const componentId = 'manager-order-edit-modal'

    const [name, setName] = useState(props.name);
    const [phone, setPhone] = useState(props.phone);
    const [updateCount, setUpdateCount] = useState(0);

    const initializeInput = () => {
        setName(props.name);
        setPhone(props.phone);
        setUpdateCount(0);
    }

    if (updateCount === 0 && props.name != '이름') {
        initializeInput();
        setUpdateCount(1);
    }

    const updatePhone = (e) => {
        if (!isNaN(e.nativeEvent.data)) {
            const phoneNumberInput = e.target.value.replace(/-/g,'');
            if (phoneNumberInput.length < 12) {
                if (phoneNumberInput.length < 3) {
                    setPhone(e.target.value);
                } else {
                    if (phoneNumberInput.length < 4) {
                        setPhone(e.target.value+'-');
                    } else {
                        if (phoneNumberInput.length < 7) {
                            setPhone(phoneNumberInput.substring(0,3) + '-' + phoneNumberInput.substring(3));
                        } else {
                            setPhone(phoneNumberInput.substring(0,3) + '-' + phoneNumberInput.substring(3,7) + '-' + phoneNumberInput.substring(7));
                        }
                    } 
                } 
            }
        }
        if (e.nativeEvent.data === null) setPhone(e.target.value);
    }

    let isEditable = 
        (name && 
         phone && 
         (phone !== '01000000000') &&
         (phone !== '010-0000-0000'));

    let editButtonClickEvent = () => {
        dispatch(saveManagerOrderInfo({name,phone}));
    }

    let EditButton;
    if (isEditable) {
        EditButton =
            <form method="dialog" className='manager-order-edit-modal-close-button-container'>
                <QuadrangleButton
                    paddingTop={12}
                    paddingBottom={12}
                    borderRadius={14}
                    text='수정하기'
                    clickEvent={editButtonClickEvent}/>
            </form>
    }

    return (
        <Modal id={componentId} closeEvent={initializeInput}>
            <div className='manager-order-edit-modal'>
                <span className='manager-order-edit-modal-title'>보내는 사람</span>
                <input className='manager-order-edit-modal-input' value={name} onChange={(e) => setName(e.target.value)}></input>

                <span className='manager-order-edit-modal-title'>연락처</span>
                <input className='manager-order-edit-modal-input' value={phone} onChange={(e) => updatePhone(e)}></input>

                <div style={{'minHeight':'50px'}}></div>

                {EditButton}
            </div>
        </Modal>
    )
}

ManagerOrderEditModal.defaultProps = {
    name: '',
    phone: '',
    email: '',
}