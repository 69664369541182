import './ManagerOrderCheckBox.css';
import CheckButton from '../CheckButton/CheckButton';

export default function ManagerOrderCheckBox(props) {
    let description;
    if (props.isOn) {
        description = 
        <span className='manager-order-check-box-description on'>
            매장에서 받은 주문
        </span>
    } else {
        description = 
        <span className='manager-order-check-box-description off'>
            매장에서 받은 주문
        </span>
    }
    return (
        <div className='manager-order-check-box-container'>
            <CheckButton isOn={props.isOn} size={24} clickEvent={props.clickEvent}/>
            {description}
        </div>
    )
}

const defaultClickEvent = () => {
    
}

ManagerOrderCheckBox.defaultProps = {
    isOn: false,
    clickEvent: defaultClickEvent
}