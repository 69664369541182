import axios from "axios";

import { getCookie } from "./cookie";

export const orderWithTossPayments = (orderInfo) => {
    const { TossPayments } = window;
    const tossPayments = TossPayments(process.env.REACT_APP_TOSSPAY_CLIENT_KEY);
    const currentTime = new Date();

    const currentYear = currentTime.getFullYear();
    let currentMonth;
    currentMonth = currentTime.getMonth() + 1;
    if (currentMonth < 10) {
        currentMonth = '0' + String(currentMonth);
    } else {
        currentMonth = String(currentMonth);
    }
    let currentDate;
    currentDate = currentTime.getDate();
    if (currentDate < 10) {
        currentDate = '0' + String(currentDate);
    } else {
        currentDate = String(currentDate);
    }

    let currentHour;
    currentHour = currentTime.getHours();
    if (currentHour < 10) {
        currentHour = '0' + String(currentHour);
    } else {
        currentHour = String(currentHour);
    }
    let currentMinute;
    currentMinute = currentTime.getMinutes();
    if (currentMinute < 10) {
        currentMinute = '0' + String(currentMinute);
    } else {
        currentMinute = String(currentMinute);
    }
    let currentSecond;
    currentSecond = currentTime.getSeconds();
    if (currentSecond < 10) {
        currentSecond = '0' + String(currentSecond);
    } else {
        currentSecond = String(currentSecond);
    }
    
    const orderId = String(currentYear) + String(currentMonth) + String(currentDate) 
            + "_" + String(currentHour) + String(currentMinute) + String(currentSecond)
            + "_" + String(orderInfo.userId);
    const successUrl = process.env.REACT_APP_PAY_SUCCESS_REDIRECT_URL + '/' + orderInfo.orderType;
    const failUrl = process.env.REACT_APP_REDIRECT_URL;
    tossPayments.requestPayment('카드', {
        amount: orderInfo.totalPrice,
        orderId: orderId,
        orderName: orderInfo.orderName,
        customerName: orderInfo.customerName,
        successUrl: successUrl,
        failUrl: failUrl,
    })
}

export const postProcessOrder = async(type) => {
    const params = new URL(document.location.toString()).searchParams;

    const orderId = params.get("orderId");
    const paymentKey = params.get("paymentKey");
    const amount = params.get("amount");
    const token = getCookie('token');

    if (token) {
        try {
            const response = await axios.post('https://api.madinbakery.com/order',
            {
                "token": token,
                "type": type,
                "orderId": orderId,
                "paymentKey": paymentKey,
                "payedMoney": amount
            });
            return response.data.order
        } catch(err) {
            console.log(err);
        }
    }
}

export const getOrderInfo = async(id) => {
    const token = getCookie('token');
    
    if (token) {
        try {
            const response = await axios.get('https://api.madinbakery.com/order/',{
                params: 
                    {
                        token: token,
                        id: id,
                    },
            });
            return response.data.order
        } catch(err) {
            console.log(err);
        }
    }
}

export const cancelOrder = async(id) => {
    const token = getCookie('token');

    if (token) {
        try {
            const response = await axios.delete('https://api.madinbakery.com/order/' + id,{
                data: 
                    {
                        token: token,
                    },
            });
            return response.data.user
        } catch(err) {
            console.log(err);
        }
    }
}

export const updatePickupTime = async(id,pickupTime) => {
    const token = getCookie('token');

    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/order/'+id,{
                "token": token,
                "pickupTime": pickupTime,
            });
            return response.data.updatedOrder;
        } catch(err) {
            console.log(err);
        }
    }
}

export const managerOrder = async(orderInfo) => {
    const token = getCookie('token');

    if (token) {
        try {
            await axios.post('https://api.madinbakery.com/order/manager',
            {
                "token": token,
                "type": orderInfo.type,
                "managerOrderInfo": orderInfo.managerOrderInfo,
            }).then ((res) => {
                window.location.href = "/order/manager/complete/"+res.data.order._id;
            });
        } catch(err) {
            console.log(err);
        }
    }
}

export const cancelManagerOrder = async(id) => {
    const token = getCookie('token');

    if (token) {
        try {
            const response = await axios.delete('https://api.madinbakery.com/order/manager/' + id,{
                data: 
                    {
                        token: token,
                    },
            });
            return response.data.user
        } catch(err) {
            console.log(err);
        }
    }
}