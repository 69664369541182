import './ManagerOrderInfoBox.css';

import { useState } from 'react';

import UnitBoxContainer from '../UnitBoxContainer/UnitBoxContainer';
import ManagerOrderEditModal from '../ManagerOrderEditModal/ManagerOrderEditModal';
import ManagerOrderEditMobileModal from '../ManagerOrderEditMobileModal/ManagerOrderEditMobileModal';
import { isEditable } from '@testing-library/user-event/dist/utils';

export default function ManagerOrderInfoBox(props) {
    const [isMobileModalOn, setMobileModalOn] = useState(false);

    const openMobileModal = () => {
        setMobileModalOn(true);
    }

    const closeMobileModal = () => {
        setMobileModalOn(false);
    }

    const showManagerOrderEditModal = () => {
        if (window.innerWidth > 700) {
            const ShippingEditModal = document.getElementById('manager-order-edit-modal');
            ShippingEditModal.showModal();
        } else {
            openMobileModal();
        }
    }

    let ManagerOrderEditButton;
    let ManagerOrderInfoBoxSpacer;
    if (props.isEditable) {
        ManagerOrderEditButton =
            <div className='manager-order-info-box-row edit-button' onClick={() => showManagerOrderEditModal()}>
                <button className='manager-order-info-box-edit-button'>
                    <span className='manager-order-info-box-edit-button-text'>수정하기</span>
                </button>
            </div>
        ManagerOrderInfoBoxSpacer = <div style={{'minHeight':'5px'}}></div>
    }

    return (
        <UnitBoxContainer>
            <div className='manager-order-info-box'>
                <div className='manager-order-info-box-row'>
                    <span className='manager-order-info-box-text'>보내는 사람</span>
                    <span className='manager-order-info-box-text'>{props.name}</span>
                </div>
                <div className='manager-order-info-box-row'>
                    <span className='manager-order-info-box-text'>연락처</span>
                    <span className='manager-order-info-box-text'>{props.phone}</span>
                </div>
                {ManagerOrderEditButton}
                {ManagerOrderInfoBoxSpacer}
            </div>
            <ManagerOrderEditModal
                name={props.name}
                phone={props.phone}
                email={props.email} />
            <ManagerOrderEditMobileModal
                name={props.name}
                phone={props.phone}
                email={props.email}
                isOn={isMobileModalOn}
                closeEvent={closeMobileModal}/>
        </UnitBoxContainer>
    )
}

ManagerOrderInfoBox.defaultProps = {
    name: '이름',
    phone: '010-0000-0000',
    isEditable: true,
}