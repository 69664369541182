import '../default.css';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from "../components/Header/Header";
import OrderSheetContentsContainer from '../components/OrderSheetContentsContainer/OrderSheetContentsContainer';
import ShippingContentsContainer from '../components/ShippingContentsContainer/ShippingContentsContainer';
import CustomerInfoContentsContainer from '../components/CustomerInfoContentsContainer/CustomerInfoContentsContainer';
import OrderCompleteGuide from '../components/OrderCompleteGuide/OrderCompleteGuide';
import OrderTimeGuideContentsContainer from '../components/OrderTimeGuideContentsContainer/OrderTimeGuideContentsContainer';
import HomeReturnButton from '../components/HomeReturnButton/HomeReturnButton';
import ManagerOrdrInfoContentsContainer from '../components/ManagerOrderInfoContentsContainer/ManagerOrderInfoContentsContainer';

import { authUser } from '../functions/auth';
import { saveUserInfo, login, logout } from '../_reducers/user';
import { getOrderInfo } from '../functions/order';
import { saveOrder } from '../_reducers/order';

export default function ManagerOrderCompleteView() {
    const user = useSelector(state => state.user);
    const order = useSelector(state => state.order);
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        authUser().then((user) => {
            if (user) {
                dispatch(login());
                dispatch(saveUserInfo(user));
            } else {
                dispatch(logout());
            }
        })
        getOrderInfo(id).then((order) => {
            dispatch(saveOrder(order));
        })
    }, []);

    let cartItemNumber;
    if (user.deliveryCart && user.pickupCart && user.presentCart) {
        cartItemNumber = user.deliveryCart.length+user.pickupCart.length+user.presentCart.length;
    } else {
        cartItemNumber = 0;
    }

    let shippingContents;
    if ((order.order.type === 'delivery') || (order.order.type === 'present')) {
        shippingContents = 
            <div style={{'width':'100%','maxWidth':'600px','paddingBottom':'30px'}}>
                <ShippingContentsContainer 
                    shippingList={[order.order.shipping]} 
                    activatedIndex={user.shippingIndex} 
                    userId={user.id}
                    isEditable={false}
                    isCheckable={false}/>
            </div>
    }

    let timeGuide;
    if (order.order.type === 'pickup') {
        timeGuide =
            <div style={{'width':'100%','maxWidth':'600px','paddingBottom':'30px'}}>
                <OrderTimeGuideContentsContainer
                    date={order.order.pickupDate}
                    time={order.order.pickupTime}
                    type={order.order.type}
                    />
            </div>
    }
    if (order.order.type === 'present') {
        timeGuide =
            <div style={{'width':'100%','maxWidth':'600px','paddingBottom':'30px'}}>
                <OrderTimeGuideContentsContainer
                    date={order.order.presentDate}
                    time={order.order.presentTime}
                    type={order.order.type}
                    />
            </div>
    }

    return (
        <div className="page padding-bottom-minimized">
            <Header isLogined={user.isLogined} name={user.name} cartItemNumber={cartItemNumber} userClass={user.class}/>
            <div className='contents-container'>
                <div className='contents'>
                    <div style={{'minHeight':'60px'}}></div>
                    <OrderCompleteGuide/>
                    <div style={{'minHeight':'60px'}}></div>
                    <ManagerOrdrInfoContentsContainer 
                        name={order.order.managerOrderInfo.name} 
                        phone={order.order.managerOrderInfo.phone} 
                        isEditable={false}/>
                    <div style={{'minHeight':'30px'}}></div>
                    <OrderSheetContentsContainer items={order.order.menus} status={order.order.type}/>
                    <div style={{'minHeight':'30px'}}></div>
                    {shippingContents}
                    {timeGuide}
                    <div style={{'minHeight':'30px'}}></div>
                    <HomeReturnButton/>
                </div>  
            </div>
        </div>
    )
}