import './ManagerOrderEditMobileModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MobileModal from '../MobileModal/MobileModal';

import { updateUserInfo } from '../../functions/user';
import { saveManagerOrderInfo } from '../../_reducers/user';

export default function ManagerOrderEditMobileModal(props) {
    const dispatch = useDispatch();

    const [name,setName] = useState(props.name);
    const [phone,setPhone] = useState(props.phone);
    const [updateCount, setUpdateCount] = useState(0);

    const initializeInput = () => {
        setName(props.name);
        setPhone(props.phone);
        setUpdateCount(0);
    }

    if (updateCount === 0 && props.name !== '이름') {
        initializeInput();
        setUpdateCount(1);
    }

    const isEditable = (name && phone);

    const updatePhone = (e) => {
        if (!isNaN(e.nativeEvent.data)) {
            const phoneNumberInput = e.target.value.replace(/-/g,'');
            if (phoneNumberInput.length < 12) {
                if (phoneNumberInput.length < 3) {
                    setPhone(e.target.value);
                } else {
                    if (phoneNumberInput.length < 4) {
                        setPhone(e.target.value+'-');
                    } else {
                        if (phoneNumberInput.length < 7) {
                            setPhone(phoneNumberInput.substring(0,3) + '-' + phoneNumberInput.substring(3));
                        } else {
                            setPhone(phoneNumberInput.substring(0,3) + '-' + phoneNumberInput.substring(3,7) + '-' + phoneNumberInput.substring(7));
                        }
                    } 
                } 
            }
        }
        if (e.nativeEvent.data === null) setPhone(e.target.value);
    }

    let editButtonClickEvent = () => {
        dispatch(saveManagerOrderInfo({name,phone}));
    }

    const closeEvent = () => {
        props.closeEvent();
        initializeInput();
    }
    const updateCustomer = () => {
        editButtonClickEvent();
        props.closeEvent();
    }

    return (
        <MobileModal isOn={props.isOn} 
                     closeEvent={closeEvent} 
                     height='90vh'
                     isCompleteButtonNeeded={isEditable}
                     completeEvent={updateCustomer}
                     completeText='수정하기'>
            <div className='manager-order-edit-mobile-modal'>
                <span className='manager-order-edit-mobile-modal-title'>보내는 사람</span>
                <input className='manager-order-edit-mobile-modal-input' value={name} onChange={(e) => setName(e.target.value)}></input>

                <span className='manager-order-edit-mobile-modal-title'>연락처</span>
                <input className='manager-order-edit-mobile-modal-input' value={phone} onChange={(e)=>updatePhone(e)}></input>
            </div>
        </MobileModal>
    )
}

const defaultClickEvent = () => {
    
}

ManagerOrderEditMobileModal.defaultProps = {
    name: "이름",
    phone: '010-0000-0000',
    closeEvent: defaultClickEvent,
}