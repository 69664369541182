import './ManagerOrderPayedCheckBox.css';
import CheckButton from '../CheckButton/CheckButton';

export default function ManagerOrderPayedCheckBox(props) {
    let description;
    if (props.isOn) {
        description = 
        <span className='manager-order-payed-check-box-description on'>
            결제 완료했어요.
        </span>
    } else {
        description = 
        <span className='manager-order-payed-check-box-description off'>
            결제 완료했어요.
        </span>
    }
    return (
        <div className='manager-order-payed-check-box-container'>
            <CheckButton isOn={props.isOn} size={24} clickEvent={props.clickEvent}/>
            {description}
        </div>
    )
}

const defaultClickEvent = () => {
    
}

ManagerOrderPayedCheckBox.defaultProps = {
    isOn: false,
    clickEvent: defaultClickEvent
}