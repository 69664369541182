import axios from "axios";
import qs from "qs";

import { getCookie } from "./cookie";

export const updateUserInfo = async(name, phone,email) => {
    try {
        const token = getCookie('token');
        const response = await axios.patch('https://api.madinbakery.com/user',{
            "name": name,
            "phone": phone,
            "token": token,
            "email": email
        });
        let user = response.data.user;
        return user
    } catch(err) {
        console.log(err);
    } 
}

export const editShipping = async(id, name, phone, tag, request) => {
    try {
        const token = getCookie('token');
        await axios.patch('https://api.madinbakery.com/shipping/' + id,{
            "name": name,
            "phone": phone,
            "token": token,
            "tag": tag,
            "request":request,
        });
        const authResponse = await axios.post('https://api.madinbakery.com/user/auth',{
            "token": token
        });
        if (authResponse.data.user) {
            return (authResponse.data.user);
        }
    } catch(err) {
        console.log(err);
    } 
}

export const addShipping = async(name, phone, basicAddress, detailAddress, postalCode) => {
    try {
        const token = getCookie('token');
        await axios.post('https://api.madinbakery.com/shipping/',{
            "name": name,
            "phone": phone,
            "basicAddress": basicAddress,
            "detailAddress": detailAddress,
            "token": token,
            "tag": "배송지",
            "postalCode": postalCode,
        });
        const authResponse = await axios.post('https://api.madinbakery.com/user/auth',{
            "token": token
        });
        if (authResponse.data.user) {
            return (authResponse.data.user);
        }
    } catch(err) {
        console.log(err);
    } 
}

export const deleteShipping = async(id) => {
    try {
        const token = getCookie('token');
        await axios.delete('https://api.madinbakery.com/shipping/'+id,{
                data: {
                    "token": token,
                } 
        });
        const authResponse = await axios.post('https://api.madinbakery.com/user/auth',{
            "token": token
        });
        if (authResponse.data.user) {
            return (authResponse.data.user);
        }
    } catch(err) {
        console.log(err);
    }
}

export const selectShippingIndex = async(index) => {
    try {
        const token = getCookie('token');
        const response = await axios.patch('https://api.madinbakery.com/user/',{
            "token": token,
            "shippingIndex": index,
        });
        return response.data.user
    } catch(err) {
        console.log(err);
    } 
}

export const selectDate = async(year,month,date) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/user', {
                "token": token,
                "date": convertDate(year,month,date)
            });
            return response.data.user
        } catch(err) {
            console.log(err);
        }
    }
}

export const selectTime = async(time) => {
    const token = getCookie('token');
    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/user', {
                "token": token,
                "time": time,
            });
            return response.data.user
        } catch(err) {
            console.log(err);
        }
    }
}

export const refreshCart = async(orderType) => {
    const token = getCookie('token');
    if (token) {
        const params = {
            token,
            orderType
        }
        try {
            const response = await axios.get('https://api.madinbakery.com/user/cart', { params });
            return response.data.user
        } catch(err) {
            console.log(err);
        }
    }
}

function convertDate(year,month,date) {
    const yearString = String(year);
    let monthString = String(month);
    if (month < 10) {
        monthString = "0" + monthString;
    }
    let dateString = String(date);
    if (date < 10) {
        dateString = "0" + dateString;
    }
    return (yearString + "-" + monthString + "-" + dateString)
}