import UnitContentsContainer from "../UnitContentsContainer/UnitContentsContainer";
import ManagerOrderInfoBox from "../MangerOrderInfoBox/MangerOrderInfoBox";
import { isEditable } from "@testing-library/user-event/dist/utils";

export default function ManagerOrdrInfoContentsContainer(props) {
    return (
        <UnitContentsContainer title='고객 정보'>
            <ManagerOrderInfoBox
                name={props.name}
                phone={props.phone}
                isEditable={props.isEditable}/>
        </UnitContentsContainer>
    )
}

ManagerOrdrInfoContentsContainer.defaultProps = {
    name: '',
    phone: '',
    isEditable: true,
}