import './OrderList.css';

import { useState } from 'react';

import Order from "../Order/Order";
import SimpleOrder from '../SimpleOrder/SimpleOrder';
import ToggleButton from '../ToggleButton/ToggleButton';
import UnitBoxContainer from '../UnitBoxContainer/UnitBoxContainer';

export default function OrderList(props) {
    const [isCanceledOrderShow, setCanceledOrderShow] = useState(false);
    const [isSimpleOrderShow, setSimpleOrderShow] = useState(false);

    let date;
    if (props.orders) {
        switch(props.orders[0].type) {
            case 'delivery':
                date = props.orders[0].createdAt.split('T')[0];
                break;
            case 'pickup':
                date = props.orders[0].pickupDate;
                break;
            case 'present':
                date = props.orders[0].presentDate;
                break;
            default:
                date = props.orders[0].createdAt.split('T')[0];
                break;
        }
    }

    let Orders;
    let orderCounts = 0;
    let validOrderCounts = 0;
    if (props.orders) {
        if (props.orders.length) {
            Orders = props.orders.map((order,index) => {
                orderCounts ++;
                if (order.status !== '결제취소') {
                    validOrderCounts ++;
                }
                let address
                if (order.shipping) {
                    address = order.shipping.basicAddress + ' ' + order.shipping.detailAddress + '(' + order.shipping.postalCode + ')';
                }
                let reciever;
                if (order.shipping) {
                    reciever = order.shipping.name;
                }
                if (!isCanceledOrderShow) {
                    if (order.status !== '결제취소') {
                        if (!isSimpleOrderShow) {
                            return (
                                <Order
                                    id={order._id}
                                    customer={order.customer}
                                    items={order.menus}
                                    totalPrice={order.payedMoney}
                                    address={address}
                                    reciever={reciever}
                                    phone={order.customer.phone}
                                    status={order.status}
                                    type={order.type}
                                    pickupTime={order.pickupTime}
                                    pickupDate={order.pickupDate}
                                    presentTime={order.presentTime}
                                    key={'order-'+index}
                                    managerOrderInfo = {order.managerOrderInfo}
                                    date={date}
                                    isDateNeeded={props.isDateNeeded}/>
                            )
                        } else {
                            return (
                                <SimpleOrder
                                    id={order._id}
                                    customer={order.customer}
                                    phone={order.customer.phone}
                                    items={order.menus}
                                    totalPrice={order.payedMoney}
                                    type={order.type}
                                    pickupTime={order.pickupTime}
                                    pickupDate={order.pickupDate}
                                    status={order.status}
                                    key={'order-'+index}
                                    date={date}
                                    isDateNeeded={props.isDateNeeded}
                                    managerOrderInfo = {order.managerOrderInfo}/>
                            )
                        }
                        
                    }
                } else {
                    if (order.status === '결제취소') {
                        if (!isSimpleOrderShow) {
                            return (
                                <Order
                                    id={order._id}
                                    customer={order.customer}
                                    items={order.menus}
                                    totalPrice={order.payedMoney}
                                    address={address}
                                    reciever={reciever}
                                    phone={order.customer.phone}
                                    status={order.status}
                                    type={order.type}
                                    pickupTime={order.pickupTime}
                                    pickupDate={order.pickupDate}
                                    presentTime={order.presentTime}
                                    key={'order-'+index}
                                    date={date}
                                    isDateNeeded={props.isDateNeeded}
                                    managerOrderInfo = {order.managerOrderInfo}/>
                            )
                        } else {
                            return (
                                <SimpleOrder
                                    id={order._id}
                                    customer={order.customer}
                                    phone={order.customer.phone}
                                    items={order.menus}
                                    totalPrice={order.payedMoney}
                                    type={order.type}
                                    pickupTime={order.pickupTime}
                                    pickupDate={order.pickupDate}
                                    status={order.status}
                                    key={'order-'+index}
                                    date={date}
                                    isDateNeeded={props.isDateNeeded}
                                    managerOrderInfo = {order.managerOrderInfo}/>
                            )
                        }
                    }
                }
            })
        }        
    }

    let canceledOrderExistGuide;
    if ((!isCanceledOrderShow)) {
        if ((orderCounts > 0) && (validOrderCounts === 0)) {
            canceledOrderExistGuide =
                <UnitBoxContainer>
                    <div className='order-list-canceled-order-exist-guide-container'>
                        <span className='order-list-canceled-order-exist-guide'>
                            ! 취소된 주문이 있어요 !
                        </span>
                        <span className='order-list-canceled-order-exist-guide'>
                            취소 주문 보기를 누르고 취소 주문 건을 확인해보세요.
                        </span>
                    </div>
                </UnitBoxContainer>
        }
    } else {
        if (orderCounts === validOrderCounts) {
            canceledOrderExistGuide =
                <UnitBoxContainer>
                    <div className='order-list-canceled-order-exist-guide-container'>
                        <span className='order-list-canceled-order-exist-guide'>
                            취소된 주문이 없어요.
                        </span>
                    </div>
                </UnitBoxContainer>
        }
    }
    

    const canceledOrderButtonClickEvent = () => {
        if (isCanceledOrderShow) {
            setCanceledOrderShow(false);
        } else {
            setCanceledOrderShow(true);
        }
    }

    const simpleOrderButtonClickEvent = () => {
        if (isSimpleOrderShow) {
            setSimpleOrderShow(false);
        } else {
            setSimpleOrderShow(true);
        }
    }

    return (
        <div className="order-list">
            <div className='order-list-button-container'>
                <span className='order-list-button-title'>취소 주문 보기</span>
                <ToggleButton isOn={isCanceledOrderShow} clickEvent={canceledOrderButtonClickEvent}/>
            </div>
            <div className='order-list-button-container'>
                <span className='order-list-button-title'>간략하게 보기</span>
                <ToggleButton isOn={isSimpleOrderShow} clickEvent={simpleOrderButtonClickEvent}/>
            </div>
            {Orders}
            {canceledOrderExistGuide}
        </div>
    )
}

OrderList.defaultProps = {
    isDateNeeded: false,
    orders: [
        {
            menus: [
                {
                    name: '제품명1',
                    price: 30000,
                    tag: '인기',
                    menuClass: '',
                    stock: 100,
                    orderType: 'delivery',
                    quantity: 1,
                    option: {},
                    isChecked: true,
                    imageUrl: '',
                    detailImageUrl: [],
                    intro: '',
                    options: [
                        {name: '1호', price: 30000},
                        {name: '2호', price: 40000},
                    ],
                    minimumOrderQuantity: 1,
                    gradients: [],
                    detail: '',
                    purchaseable: true,
                    id: '000',
                },
                {
                    name: '제품명2',
                    price: 3000,
                    tag: '인기',
                    menuClass: '',
                    stock: 100,
                    orderType: 'delivery',
                    quantity: 1,
                    option: {},
                    isChecked: true,
                    imageUrl: '',
                    detailImageUrl: [],
                    intro: '',
                    options: [
                        {name: '1호', price: 30000},
                        {name: '2호', price: 40000},
                    ],
                    minimumOrderQuantity: 1,
                    gradients: [],
                    detail: '',
                    purchaseable: true,
                    id: '000',
                }
            ],
            customer: {
                _id: '000',
                name: '장원영',
                phone: '010-1241-1201',
                email: 'abcd@gmail.com'
            },
            shipping: {
                _id: '125125',
                name: '안유진',
                phone: '010-0125-1251',
                basicAddress: '경기도 화성시 시청로102번길 11',
                detailAddress: '205동 1102호',
                postalCode: '00406',
                request: '',
                tag: '배송지',
                userId: '0102',
            },
            mileageUse: 0,
            presentDate: '',
            presentTime: '',
            deliveryDate: '',
            pickupDate: '',
            pickupTime: '',
            type: 'delivery',
            status: '결제완료',
            orderPrice: 33000,
            payedMoney: 33000,
            orderId: '1251251251',
            receipt: '',    
            paymentKey: '',
            deliveryId: '1251251',
            _id: '01010',
        }
    ],
}
